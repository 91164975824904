import { Form, Input, Select, Space } from 'antd';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useRequests,
  formRequestErrorHandler,
  Gender,
  getCharacterRequests,
  UserType,
} from '../../../../api';
import {
  Loader,
  CRUDKey,
  AvatarUploader,
  WideButton,
  SelectAutoClose,
  If,
} from '../../../../common';
import {
  confirmDangerousAction,
  createNotification,
  NotificationType,
  RouteData,
} from '../../../../utils';
import HandleFormItem from './handle-form-item.component';
import { useInitStore } from '../../../../stores';

import './creator-character.component.css';

const { TextArea } = Input;
const { Option } = Select;

const Character: React.FC<{
  rawRoutes?: { [key: string]: RouteData[] };
}> = ({ rawRoutes }) => {
  const params = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const user = useInitStore((state) => state.user);

  const isAdmin = false; // user?.type === UserType.Admin;
  // const isCreator = user?.type == UserType.Creator;

  const { request, requestKey, response, isLoading, error } = useRequests({
    requests: getCharacterRequests(params.characterId!, isAdmin),
    callbacks: {
      onSuccess: (data, key) => {
        if (key === CRUDKey.Read) {
          form.setFieldsValue(data);
        } else if (key === CRUDKey.Update) {
          createNotification({
            key: 'characterUpdated',
            message: 'Saved successfully.',
            type: NotificationType.Success,
            duration: 6,
          });
        } else if (key === CRUDKey.Delete) {
          createNotification({
            key: 'characterDeleted',
            message: 'Character deleted successfully.',
            type: NotificationType.Success,
            duration: 6,
          });

          navigate('/creator');
        }
      },
      onError: formRequestErrorHandler(form),
    },
  });

  useEffect(() => {
    if (params.characterId) request.read();
  }, [params.characterId]);

  return (
    <Loader
      error={requestKey === CRUDKey.Read ? error : undefined}
      isLoading={isLoading}
    >
      <Form
        form={form}
        onFinish={request.update}
        initialValues={response}
        layout="vertical"
        requiredMark={false}
        className="form"
        style={{ width: '100%' }}
      >
        <div className="creator-character-container">
          <Space direction="horizontal" align="start" size={60}>
            <Form.Item label="Avatar" tooltip="The recommended size is 260x260">
              <AvatarUploader
                templateId={
                  process.env.REACT_APP_TRANSLOADIT_TEMPLATE_CHARACTER_AVATAR!
                }
                fields={{ character_id: response?.id }}
                initialValue={response?.avatarUrl}
                size={120}
              />
            </Form.Item>

            <Form.Item label="Cover" tooltip="The recommended size is 700x875">
              <AvatarUploader
                templateId={
                  process.env.REACT_APP_TRANSLOADIT_TEMPLATE_CHARACTER_COVER!
                }
                fields={{ character_id: response?.id }}
                initialValue={response?.coverUrl}
                width={120}
                height={150}
              />
            </Form.Item>
          </Space>

          <Space
            direction="vertical"
            size="middle"
            style={{
              width: '100%',
              maxWidth: 'min(600px, 90%)',
              marginTop: '20px',
            }}
          >
            <Form.Item
              name="name"
              label="Display Name"
              rules={[
                { required: true, message: 'Display name is required' },
                {
                  max: 30,
                  message: 'Display name must be maximum 30 characters',
                },
              ]}
            >
              <Input placeholder="Display Name" />
            </Form.Item>

            <HandleFormItem rawRoutes={rawRoutes} />

            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: 'Gender is required' }]}
            >
              <SelectAutoClose placeholder="Select gender">
                <Option value={Gender.Male}>Male</Option>
                <Option value={Gender.Female}>Female</Option>
              </SelectAutoClose>
            </Form.Item>

            <Form.Item
              name="bio"
              label="Bio"
              tooltip="A short description to introduce yourself in your profile"
              rules={[
                {
                  max: 500,
                  message: 'Bio must be maximum 500 characters',
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                style={{ maxWidth: '800px' }}
                placeholder="It's your favourite girl next door, Jane Doe! Come chat with me here!"
              />
            </Form.Item>

            <Form.Item
              name="introMessage"
              label="Intro Message"
              tooltip="A voice message to introduce yourself in your profile"
              rules={[
                {
                  max: 1000,
                  message: 'Intro message must be maximum 1000 characters',
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 5, maxRows: 5 }}
                placeholder="Hey, it's me Jane Doe... your guide to positive energy... Want to chat live and share content?"
              />
            </Form.Item>

            <Form.Item
              name="welcomeMessage"
              label="Welcome Message"
              tooltip="The first message sent in every new chat to welcome the user"
              rules={[
                {
                  max: 1000,
                  message: 'Welcome message must be maximum 1000 characters',
                },
              ]}
            >
              <TextArea
                autoSize={{ minRows: 5, maxRows: 5 }}
                placeholder="Hey there... I'm glad you're here! I can keep you company for as long as you like. You can also request content... like photos and videos... Tell me about yourself! What do you like?"
              />
            </Form.Item>

            <Space>
              <WideButton
                label="Save"
                disabled={isLoading}
                onClick={form.submit}
                style={{ width: '160px' }}
                accent
              >
                <SaveOutlined />
              </WideButton>

              <If condition={user?.type === UserType.Admin}>
                <WideButton
                  label="Delete"
                  disabled={isLoading}
                  onClick={() =>
                    confirmDangerousAction({
                      action: 'Delete',
                      name: 'this profile',
                      onConfirm: request.delete,
                    })
                  }
                  style={{ width: '160px' }}
                  light
                >
                  <DeleteOutlined />
                </WideButton>
              </If>
            </Space>
          </Space>
        </div>
      </Form>
    </Loader>
  );
};

export default Character;
