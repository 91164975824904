import { Avatar, Drawer, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useLayoutDisplay } from '../../hooks';
import {
  CloseSvg,
  CreatorSvg,
  DiscordSvg,
  ExitSvg,
  KnowledgeSvg,
  MediaSvg,
  ProductSvg,
  ProfileSvg,
  StarSvg,
} from '../common-svg.component';
import { MenuDrawerLink } from './menu-drawer-link.component';
import {
  PrivacySvg,
  SignOutSvg,
  SupportSvg,
  TermsSvg,
} from './menu-drawer-link-svg.component';
import { useInitStore, useAuthStore, useMiscStore } from '../../../stores';
import { WideButton } from '../wide-button/wide-button.component';
import { If } from '../if.component';
import {
  ChatSvg,
  // ExploreSvg,
  WalletSvg,
} from '../navigation-button/navigation-button-svg.component';
import {
  CharacterListRequest,
  CharacterParams,
  ReadCharacterRequest,
  useAPIRequest,
  UserType,
} from '../../../api';
import {
  clearMixpanelUser,
  trackMixpanelEvent,
} from '../../../third-party/libraries/mixpanel';

import './menu-drawer.component.css';

export const MenuDrawer: FC = () => {
  const params = useParams();

  const { isMobile } = useLayoutDisplay();

  const { menuOpen, setMenuOpen } = useMiscStore();
  const { token, guestToken, clearToken } = useAuthStore();
  const { user, resetInitData } = useInitStore();

  const signedIn = !!token && token !== guestToken;

  const close = () => setMenuOpen(false);

  const isCreatorRoute = location.pathname.includes('/creator');
  const characterPath = `/creator/character/${params.characterId}`;
  const inCharacterRoute = !!params.characterId;

  const { request, response, setResponse } = useAPIRequest<CharacterParams>(
    ReadCharacterRequest,
    { immediate: false },
  );

  const { request: requestList, response: listResponse } = useAPIRequest<{
    data: CharacterParams[];
  }>(CharacterListRequest, { immediate: false });

  useEffect(() => {
    if (params.characterId) {
      request(params.characterId);
    } else {
      setResponse(undefined);
    }
  }, [params.characterId]);

  useEffect(() => {
    if (user && !user.guest) {
      requestList();
    }
  }, [user]);

  const hasCharacters = listResponse?.data?.length || 0 > 0;

  // const hasBalance = (user?.balance || 0) > 0;

  const isCreator =
    user?.type === UserType.Creator || user?.type === UserType.Admin;

  let height: number | undefined = undefined;

  if (isMobile) {
    if (signedIn) {
      height = 520;

      if (isCreatorRoute) {
        if (hasCharacters) {
          if (inCharacterRoute) {
            height = 680;
          } else height = 510;
        } else height = 420;
      }

      // if (user?.subscription.active || hasBalance) height += 40;
    } else height = 400;
  }

  return (
    <Drawer
      open={menuOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={close}
      height={height}
    >
      <div className="menu-drawer-container">
        <div className="button-circle" onClick={close}>
          <Icon component={CloseSvg} />
        </div>

        <div className="title">More</div>

        <Space direction="vertical" size={18} style={{ padding: '0px 20px' }}>
          <If condition={signedIn && isMobile}>
            <If condition={!isCreatorRoute}>
              <Space direction="vertical" size={18} style={{ width: '100%' }}>
                {/*<MenuDrawerLink
                  icon={ExploreSvg}
                  iconWidth="24px"
                  label="Explore"
                  to="/"
                  onClick={close}
                />*/}

                <MenuDrawerLink
                  icon={ChatSvg}
                  iconWidth="24px"
                  label="Messages"
                  to="/messages"
                  onClick={close}
                />

                <MenuDrawerLink
                  icon={WalletSvg}
                  iconWidth="24px"
                  label="Wallet"
                  to="/wallet"
                  onClick={close}
                />

                {/*<If condition={user?.subscription.active || hasBalance}>
                <MenuDrawerLink
                  icon={WalletSvg}
                  iconWidth="24px"
                  label="Wallet"
                  to="/wallet"
                  onClick={close}
                />
              </If>

              <MenuDrawerLink
                icon={SubscriptionSvg}
                iconWidth="24px"
                label="Subscription"
                to="/subscription"
                onClick={close}
              />*/}

                <MenuDrawerLink
                  icon={ProfileSvg}
                  iconWidth="24px"
                  label="Account"
                  to="/account"
                  onClick={close}
                />

                <If condition={isCreator}>
                  <MenuDrawerLink
                    icon={StarSvg}
                    iconWidth="24px"
                    label="Creator"
                    to="/creator"
                    onClick={close}
                  />
                </If>
              </Space>
            </If>

            <If condition={isCreatorRoute}>
              <If condition={hasCharacters}>
                <Space direction="vertical" size={18} style={{ width: '100%' }}>
                  <If condition={response}>
                    <div style={{ width: 'fit-content' }}>
                      <Link to="/creator" onClick={close}>
                        <div
                          className="selected-profile"
                          style={{ marginBottom: '20px' }}
                        >
                          <div>
                            <Avatar
                              src={response?.avatarUrl}
                              size={48}
                              style={{ marginRight: '10px' }}
                            />
                          </div>

                          <div>
                            <div
                              className="name"
                              style={{ marginBottom: '4px' }}
                            >
                              {response?.name}
                            </div>

                            <div className="link">Switch Profile</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </If>

                  <If condition={!response}>
                    <MenuDrawerLink
                      icon={ProfileSvg}
                      iconWidth="24px"
                      label="Profiles"
                      to="/creator"
                      onClick={close}
                    />
                  </If>

                  <If condition={inCharacterRoute}>
                    <MenuDrawerLink
                      icon={StarSvg}
                      iconWidth="24px"
                      label="Overview"
                      to={`${characterPath}/overview`}
                      onClick={close}
                    />
                  </If>

                  <If condition={!inCharacterRoute}>
                    <MenuDrawerLink
                      icon={StarSvg}
                      iconWidth="24px"
                      label="Overview"
                      to="/creator/overview"
                      onClick={close}
                    />
                  </If>

                  <If condition={inCharacterRoute}>
                    <MenuDrawerLink
                      icon={ProfileSvg}
                      iconWidth="24px"
                      label="Profile"
                      to={characterPath}
                      onClick={close}
                    />
                  </If>

                  <If condition={inCharacterRoute}>
                    <MenuDrawerLink
                      icon={MediaSvg}
                      iconWidth="24px"
                      label="Media"
                      to={`${characterPath}/media`}
                      onClick={close}
                    />
                  </If>

                  <If condition={inCharacterRoute}>
                    <MenuDrawerLink
                      icon={ProductSvg}
                      iconWidth="24px"
                      label="Products"
                      to={`${characterPath}/products`}
                      onClick={close}
                    />
                  </If>

                  <If condition={inCharacterRoute}>
                    <MenuDrawerLink
                      icon={KnowledgeSvg}
                      iconWidth="24px"
                      label="Knowledge"
                      to={`${characterPath}/knowledge`}
                      onClick={close}
                    />
                  </If>

                  <If condition={!inCharacterRoute}>
                    <MenuDrawerLink
                      icon={WalletSvg}
                      iconWidth="24px"
                      label="Payouts"
                      to="/creator/payouts"
                      onClick={close}
                    />
                  </If>

                  <MenuDrawerLink
                    icon={ExitSvg}
                    iconWidth="24px"
                    label="Exit Creator Settings"
                    to="/messages"
                    onClick={close}
                  />
                </Space>
              </If>

              <If condition={!hasCharacters}>
                <Space direction="vertical" size={18} style={{ width: '100%' }}>
                  <MenuDrawerLink
                    icon={StarSvg}
                    iconWidth="24px"
                    label="Onboarding"
                    to="/creator/onboarding"
                    onClick={close}
                  />

                  <MenuDrawerLink
                    icon={ExitSvg}
                    iconWidth="24px"
                    label="Exit Creator Settings"
                    to="/messages"
                    onClick={close}
                  />
                </Space>
              </If>
            </If>
          </If>

          <MenuDrawerLink
            icon={TermsSvg}
            label="Terms & Conditions"
            to="/tos"
            onClick={close}
          />

          <MenuDrawerLink
            icon={PrivacySvg}
            label="Privacy Policy"
            to="/privacy"
            onClick={close}
          />

          <MenuDrawerLink
            icon={SupportSvg}
            label="Support"
            to={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
            target="_blank"
            onClick={close}
          />

          <If condition={!!token}>
            <MenuDrawerLink
              icon={SignOutSvg}
              label="Sign Out"
              to="/"
              onClick={() => {
                resetInitData();
                clearToken();
                close();

                trackMixpanelEvent('Sign Out');
                clearMixpanelUser();
              }}
            />
          </If>
        </Space>

        <If condition={isMobile}>
          <Space
            direction="vertical"
            size={12}
            style={{ padding: '0px 20px', marginTop: '32px' }}
          >
            <WideButton
              label="Discord"
              to={process.env.REACT_APP_DISCORD}
              target="_blank"
              onClick={close}
            >
              <Icon component={DiscordSvg} style={{ width: '16px' }} />
            </WideButton>

            <If condition={!isCreator}>
              <WideButton label="Creator?" to="/start" accent onClick={close}>
                <Icon component={CreatorSvg} style={{ width: '16px' }} />
              </WideButton>
            </If>
          </Space>
        </If>
      </div>
    </Drawer>
  );
};
