import { AxiosRequestConfig } from 'axios';
import { CharacterParams } from '../character';

export const UpdateCharacterRequest = (
  id: string,
  params: Omit<CharacterParams, 'id'>,
  admin?: boolean,
): AxiosRequestConfig => {
  return {
    url: admin ? `v1/character/${id}` : `v1/character/${id}/creator`,
    method: 'put',
    data: params,
  };
};
