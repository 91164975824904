import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import Characters from './characters/characters.component';
import Knowledge from './knowledge/knowledge.component';
import Media from './media/media.component';
import Medias from './medias/medias.component';
import { Payouts } from './payouts/payouts.component';
import Product from './product/product.component';
import Products from './products/products.component';
import Character from './character/creator-character.component';
import Waitlist from './waitlist/waitlist.component';
import CharacterOverview from './character-overview/character-overview.component';
import CreatorOverview from './overview/creator-overview.component';
import CreatorOnboarding from './onboarding/creator-onboarding.component';

export const CreatorRoutes: RouteData[] = [
  {
    path: 'start',
    title: 'Get Cloned!',
    component: Waitlist,
    layout: AppLayout,
    hideFooter: true,
    allowBackToMain: true,
    maxWidth: '100%',
  },
  {
    path: 'start/:promo',
    title: 'Get Cloned!',
    component: Waitlist,
    layout: AppLayout,
    hideFooter: true,
    allowBackToMain: true,
    maxWidth: '100%',
  },
  {
    path: 'creator',
    title: 'Profiles',
    component: Characters,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/messages',
    // TODO: user type must be creator
  },
  {
    path: 'creator/character/:characterId/overview',
    title: 'Overview',
    component: CharacterOverview,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
  {
    path: 'creator/overview',
    title: 'Overview',
    component: CreatorOverview,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
  {
    path: 'creator/onboarding',
    title: 'Onboarding',
    component: CreatorOnboarding,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/messages',
  },
  {
    path: 'creator/character/:characterId/media',
    title: 'Media',
    component: Medias,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPathFactory: (params) => {
      return `/creator/character/${params.characterId}/overview`;
    },
  },
  {
    path: 'creator/character/:characterId/media/:mediaId',
    title: 'Media',
    component: Media,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPathFactory: (params) => {
      return `/creator/character/${params.characterId}/media`;
    },
  },
  {
    path: 'creator/character/:characterId/products',
    title: 'Products',
    component: Products,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPathFactory: (params) => {
      return `/creator/character/${params.characterId}/overview`;
    },
  },
  {
    path: 'creator/character/:characterId/product/:productId',
    title: 'Products',
    component: Product,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPathFactory: (params) => {
      return `/creator/character/${params.characterId}/products`;
    },
  },
  {
    path: 'creator/character/:characterId/knowledge',
    title: 'Knowledge',
    component: Knowledge,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
  {
    path: 'creator/payouts',
    title: 'Payouts',
    component: Payouts,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
  {
    path: 'creator/character/:characterId',
    title: 'Profile',
    component: Character,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
];
