import { CRUDKey } from '../../../common';
import { Requests } from '../../hooks';
import { CharacterParams } from '../character';
import { DeleteCharacterRequest } from './delete-character.request';
import { ReadCharacterRequest } from './read-character.request';
import { UpdateCharacterRequest } from './update-character.request';

export function getCharacterRequests(
  id: string,
  admin?: boolean,
): Requests<CharacterParams> {
  return {
    [CRUDKey.Read]: () => ReadCharacterRequest(id),
    [CRUDKey.Update]: (params) => UpdateCharacterRequest(id, params, admin),
    [CRUDKey.Delete]: () => DeleteCharacterRequest(id),
  };
}
